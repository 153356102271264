<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="groups" :query="{}">
      <DefaultBtn titleButton="Salvar" icon="edit" @click="saveGroup" />
    </Mural>
    <main class="main-content-group q-px-md q-py-sm">
      <div class="row">
        <span class="title-input-form">Status</span>
      </div>
      <div>
        <q-toggle v-model="status" color="green" />
      </div>

      <div class="row">
        <div class="row col-12" v-show="isMaster">
          <label class="title-group col-12">Empresa</label>
          <q-select
            outlined
            color="default-pink"
            bg-color="grey-3"
            class="col-11"
            label="Informe a empresa"
            emit-value
            map-options
            v-model="formGroup.company_id"
            @update:model-value="filterUsersByCompany()"
            :options="listCompanies"
            :rules="[(val) => !!val || 'O campo empresa é obrigatório.']"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <div class="row col-12">
          <label class="title-group col-12"
            >Segmento <span class="required-input">*</span></label
          >
          <q-select
            v-model="formGroup.branchId"
            :options="listBranches"
            bg-color="grey-3"
            color="default-pink"
            class="col-11"
            label="Selecione o segmento para o grupo"
            :disable="isMaster && formGroup.company_id == 0 ? true : false"
            outlined
            emit-value
            map-options
            use-input
            input-debounce="0"
            :rules="[(val) => !!val || 'O segmento é obrigatório']"
            @filter="filterFnBranches"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
            <template
              v-slot:option="{ itemProps, opt, selected, toggleOption }"
            >
              <q-item v-bind="itemProps">
                <q-item-section>
                  <q-item-label v-html="opt.label" />
                </q-item-section>
                <q-item-section side>
                  <q-toggle
                    :model-value="selected"
                    @update:model-value="toggleOption(opt)"
                  />
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <div class="row col-12">
          <label class="title-group col-12">Usuário </label>
          <q-select
            v-model="formGroup.users"
            :options="listUsers"
            bg-color="grey-3"
            color="default-pink"
            class="col-11"
            label="Selecione os usuários para o grupo"
            multiple
            :disable="isMaster && formGroup.company_id == 0 ? true : false"
            outlined
            emit-value
            map-options
            use-input
            use-chips
            input-debounce="0"
            @filter="filterFnUsers"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
            <template
              v-slot:option="{ itemProps, opt, selected, toggleOption }"
            >
              <q-item v-bind="itemProps">
                <q-item-section>
                  <q-item-label v-html="opt.label" />
                </q-item-section>
                <q-item-section side>
                  <q-toggle
                    :model-value="selected"
                    @update:model-value="toggleOption(opt)"
                  />
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <div class="row col-12">
          <label class="title-group col-12"
            >Nome do grupo<span class="required-input"> *</span></label
          >
          <q-input
            class="col-11"
            outlined
            label="Digite um nome para o grupo.."
            color="default-pink"
            bg-color="grey-3"
            v-model="formGroup.group_name"
            :rules="[(val) => !!val || 'O nome do grupo é obrigatório']"
          />
        </div>

        <div class="row col-12">
          <label class="title-group col-12">Descrição do grupo</label>
          <q-input
            class="col-11"
            outlined
            label="Descrição do grupo.."
            color="default-pink"
            bg-color="grey-3"
            type="textarea"
            v-model="formGroup.description"
          />
        </div>
      </div>
    </main>

    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import { ref, reactive, onMounted } from "vue";
import GroupServices from "@/services/GroupServices";
import CompanyServices from "@/services/CompanyServices";
import UserServices from "../../../services/UserServices";
import { useRouter, useRoute } from "vue-router";
import { useQuasar } from "quasar";

export default {
  name: "EditGroup",
  components: {
    Mural,
    DefaultBtn,
  },
  setup() {
    let breadcrumbs = ref(["Novo grupo"]);
    let loading = ref(false);
    let status = ref(true);
    let listCompanies = ref([]);
    let listUsers = ref([]);
    let optionListUsers = ref([]);
    let listBranches = ref([]);
    let optionListBranches = ref([]);
    let optionListUsersByCompany = ref([]);
    let route = new useRoute();
    let router = new useRouter();
    let userServices = new UserServices();
    let groupServices = new GroupServices();
    let companyServices = new CompanyServices();
    let isMaster = ref(false);
    let $q = new useQuasar();
    let formGroup = reactive({
      group_name: "",
      description: "",
      status: "",
      company_id: 0,
      users: [],
      branchId: null,
    });

    async function listAllUsers() {
      loading.value = true;

      await userServices
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            listUsers.value.push({
              label: item.first_name + " " + item.last_name,
              value: item.id,
              company: item.company_id,
            });
          });
          optionListUsers.value = listUsers.value;
          optionListUsersByCompany.value = listUsers.value;
          loading.value = false;
        })

        .catch((e) => {
          loading.value = false;
        });
    }

    function filterFnUsers(val, update) {
      if (val === "") {
        update(() => {
          listUsers.value = optionListUsers.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listUsers.value = optionListUsers.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    async function getBranches() {
      await groupServices
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    async function saveGroup() {
      let groupId = route.query.groupId;
      formGroup.status = status.value ? "Active" : "Inactive";
      if (formGroup.company_id == null) {
        _alertOf("Selecione uma empresa", "red-9");
        return;
      }
      loading.value = true;
      if (groupId == null) {
        await groupServices
          .addGroup(formGroup)
          .then(() => {
            router.push({ name: "groups" });
            loading.value = false;
          })
          .catch((error) => {
            _alertOf("Erro ao salvar grupo", "red-9");
            loading.value = false;
            return;
          });
      } else {
        await groupServices
          .updateGroup(groupId, formGroup)
          .then(() => {
            router.push({ name: "groups" });
            loading.value = false;
          })
          .catch((error) => {
            _alertOf("Erro ao salvar grupo", "red-9");
            loading.value = false;
            return;
          });
      }
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    async function GetGroup(groupId) {
      if (groupId == null) return null;
      loading.value = true;
      var dataGroup = await groupServices.getGroup(groupId);

      formGroup.group_name = dataGroup.data.group_name;
      formGroup.description = dataGroup.data.description;
      formGroup.company_id =
        dataGroup.data.company_id == null ? 0 : dataGroup.data.company_id;
      status.value = dataGroup.data.status == "Active" ? true : false;
      loading.value = false;
      formGroup.branchId = dataGroup.data.branchId;
      dataGroup.data.users.forEach((user) => {
        formGroup.users.push(user.user);
      });

      if (isMaster.value) {
        _filterUsersWithCompanies();
      }
    }

    async function listAllCompanies() {
      loading.value = true;
      await companyServices
        .getAll()
        .then((response) => {
          response.data.map((item) => {
            listCompanies.value.push({
              label: item.company_name,
              value: item.id,
            });
          });
          loading.value = false;
        })
        .catch((e) => {
          loading.value = false;
        });
    }
    function filterUsersByCompany() {
      formGroup.users = null;
      _filterUsersWithCompanies();
    }

    function _filterUsersWithCompanies() {
      listUsers.value = optionListUsersByCompany.value.filter((v) => {
        return v.company == formGroup.company_id;
      });
      optionListUsers.value = listUsers.value;
    }

    onMounted(() => {
      let user = JSON.parse(localStorage.getItem("vuex")).userdata;

      isMaster.value = user.master == 1;

      if (isMaster.value) {
        listAllCompanies();
      }
      listAllUsers();
      GetGroup(route.query.groupId);
      getBranches();
    });

    return {
      formGroup,
      breadcrumbs,
      listCompanies,
      isMaster,
      loading,
      status,
      filterFnUsers,
      listUsers,
      filterUsersByCompany,
      saveGroup,
      listBranches,
      filterFnBranches,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content-group {
  height: 80vh;
  overflow: auto;
}
.container-options {
  overflow-y: auto;
  height: 86%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 30px 0px;
}

.title-group {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.45;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}

.title-options {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
}

.title-status {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #9c9c9c;
}

.title-content {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
}

.content-options {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 20px;
  flex: none;
  order: 1;
  height: 50px;
  flex-grow: 0;
  margin: 10px 0px;
}
.content-options:hover {
  background-color: #f3f1f1;
  border: 1px solid #fe5268;
  transition-delay: 0.2ms;
}
.chat-drop {
  background: #ffffff;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
}
.btn-drop {
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 100%;
}
//.btn-drop:hover{
//  background-color: #FFF8EE;
//  border: 1px solid #FE5268;
//  transition-delay: 0.2ms;
//}

.required-input {
  color: #fe5268;
}
</style>
